/**
 * Create something like MUI theme?
 *
 * See: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createMuiTheme.js
 * See: https://github.com/styled-components/polished
 * See: https://github.com/styled-components/styled-theming
 *
 */

import { rem, lighten, getContrast, darken } from 'polished';

const breakpoints = {
  small: '0px',
  medium: '640px',
  mediumlarge: '820px',
  large: '1024px',
  xlarge: '1280px',
  xxlarge: '1440px',
};

const breakpointClasses = ['small', 'medium', 'mediumlarge', 'large', 'xlarge']; // Separate array to be able to define more breakpoints that not currently will be used

function createBreakpoints() {
  const mediaQueries = {};

  // Object.keys(breakpointClasses).forEach((breakpoint) => {
  breakpointClasses.forEach((breakpoint) => {
    mediaQueries[breakpoint] = `(min-width: ${breakpoints[breakpoint]})`;
  });

  return mediaQueries;
}

export const colors = {
  primary: '#5E8C30',
  secondary: '#6085A6',
  third: '#34592E',
  success: '#000',
  warning: '#000',
  alert: '#000',
  cta: '#000',
  alt: '#000',
  black: '#000',
  white: '#fff',
  greyLight: '#a3a3a3',
  grey: '#3d3d3d',
  greyDark: '#181818',
};

const buttonColors = {
  primary: '#5E8C30',
  secondary: '#6085A6',
  third: '#34592E',
  success: '#000',
  warning: '#000',
  alert: '#000',
  cta: '#000',
  alt: '#000',
  black: '#000',
  white: '#fff',
};

const theme = {
  mode: 'dark',
  global: {
    radius: '999px',
    menuPadding: '0.6rem 1rem',
    textDirection: 'ltr',
    transition: {
      duration: '0.15s',
      timing: 'ease',
    },
    border: {
      color: colors.grey,
      width: '1px',
      style: 'solid',
    },
  },
  palette: {
    background: {
      body: colors.greyDark,
      content: colors.greyDark,
    },
    text: {
      primary: colors.white,
      black: colors.black,
    },
    colors,
    social: {
      facebook: '#3b5998',
      twitter: '#1da1f2',
      pinterest: '#bd081c',
      youtube: '#cd201f',
      instagram: '#e1306c',
      vimeo: '#1ab7ea',
      linkedin: '#0077b5',
      googleplus: '#dd4b39',
    },
    buttons: buttonColors,
  },
  spacing: {
    gutter: rem(20),
    columnGutter: rem(20 / 2),
    // gutter: {
    //   small: rem(20),
    //   large: rem(30),
    // }
  },
  grid: {
    columns: 16,
    maxWidth: rem(1200),
    maxWidthText: rem(820),
    breakpoints: createBreakpoints(),
  },
  typography: {
    global: {
      fontSize: '100%',
      lineHeight: 1.5,
      rendering: 'optimizeLegibility',
    },
    fonts: {
      primary: 'Source Sans Pro, sans-serif',
      heading: 'Source Sans Pro, sans-serif',
      alt: 'Source Sans Pro, sans-serif',
      button: 'Source Sans Pro, sans-serif',
    },
    weights: {
      primary: {
        normal: '400',
        bold: '700',
      },
      alt: {
        normal: '400',
        bold: '700',
      },
      heading: {
        normal: '400',
        bold: '700',
      },
      button: {
        normal: '700',
        bold: '700',
      },
    },
    heading: {
      // lineHeight: 1.32,
      lineHeight: 1.1,
      // marginBottom: '0.7em',
      marginBottom: '0.32em',
      textTransform: 'none',
      headingSizes: {
        small: {
          h1: rem(30),
          h2: rem(30),
          h3: rem(26),
          h4: rem(22),
          h5: rem(20),
          h6: rem(18),
        },
        mediumlarge: {
          h1: rem(34),
          h2: rem(34),
          h3: rem(32),
          h4: rem(28),
          h5: rem(24),
          h6: rem(20),
        },
        large: {
          h1: rem(40),
          h2: rem(40),
          h3: rem(36),
          h4: rem(32),
          h5: rem(30),
          h6: rem(24),
        },
      },
    },
    paragraph: {
      fontSize: rem(17),
      lineHeight: 1.7,
      marginBottom: '1.14rem',
    },
    anchor: {
      default: colors.primary,
      hover: lighten(0.2, colors.primary),
      decoration: 'none',
    },
    button: {
      letterSpacing: '0.01em',
      textTransform: 'none',
      buttonSizes: {
        small: rem(13),
        default: rem(16),
        large: rem(18),
      },
    },
  },
  components: {
    button: {
      radius: '3px',
      padding: '0.56rem 1.85em',
    },
    drawer: {
      background: colors.white,
      padding: '0.5rem 1rem',
      item: {
        color: colors.black,
        hover: lighten(0.2, colors.primary),
        padding: '0.75rem 1.15rem',
      },
    },
    menu: {
      item: {
        color: colors.black,
        hover: lighten(0.2, colors.primary),
        colorNegative: colors.white,
        hoverNegative: darken(0.2, colors.white),
        padding: '0.75rem 1.15rem',
        fontSize: rem(16),
        letterSpacing: '0.01em',
        textTransform: 'uppercase',
        fontWeight: '700',
      },
      itemVertical: {
        color: colors.black,
        hover: lighten(0.2, colors.primary),
        colorNegative: colors.white,
        hoverNegative: darken(0.2, colors.white),
        padding: '0.4em 1.15rem',
        fontSize: rem(19),
        letterSpacing: '0.01em',
        textTransform: 'uppercase',
        fontWeight: '700',
      },
    },
  },
};

/**
 * Helper method for easier use of breakpoint output in styled-component styles
 */
export const breakpoint = (size) => {
  return `@media ${theme.grid.breakpoints[size]}`;
};

/**
 * Helper method to generate responsive heading sizing from objects by breakpoint
 */
export const headingSizes = (sizes) => {
  let output = '';

  Object.keys(sizes).forEach((breakpointKey) => {
    let breakpointSizes = '';

    Object.keys(sizes[breakpointKey]).forEach((selector) => {
      breakpointSizes += `
        ${selector} {
          font-size: ${sizes[breakpointKey][selector]};
        }\n`;
    });

    output += `${breakpoint(breakpointKey)} {
      ${breakpointSizes}
    }\n`;
  });

  return output;
};

/**
 * Helper method to generate button variants
 */
export const buttonVariants = (variant) => {
  let output = '';

  // Sizes
  Object.keys(theme.typography.button.buttonSizes).forEach((key) => {
    // skip default size
    if (key !== 'default') {
      output += `
        &.${key} {
          font-size: ${theme.typography.button.buttonSizes[key]};
        }\n`;
    }
  });

  // Colors
  if (variant === 'hollow') {
    Object.keys(buttonColors).forEach((color) => {
      const contrastRatio = getContrast(
        buttonColors[color],
        theme.palette.background.content,
      );
      const contrastRatioHover = getContrast(buttonColors[color], '#fff');

      const textColor = contrastRatio > 2.4 ? '#fff' : '#000';
      const textColorHover = contrastRatioHover > 2.4 ? '#fff' : '#000';

      output += `
        &.${color} {
          color: ${textColor};
          border-color: ${buttonColors[color]};

          &:hover {
            background-color: ${buttonColors[color]};
            color: ${textColorHover};
          }
        }\n`;
    });
  } else {
    Object.keys(buttonColors).forEach((color) => {
      const contrastRatio = getContrast(buttonColors[color], '#fff');
      const textColor = contrastRatio > 2.4 ? '#fff' : '#000';

      output += `
        &.${color} {
          background-color: ${buttonColors[color]};
          color: ${textColor};

          &:hover {
            background-color: ${lighten(0.1, buttonColors[color])};
          }
        }\n`;
    });
  }

  return output;
};

export default theme;
