export default function(doc) {
  const type = doc.type || doc._meta.type; // eslint-disable-line no-underscore-dangle
  // console.log('Resolver', type, doc._meta.uid);

  switch (type) {
    case 'partners':
    case 'articles':
    case 'clients':
    case 'contact':
    case 'page':
      return `/${doc.uid || doc._meta.uid}`; // eslint-disable-line no-underscore-dangle
    case 'article':
      return `/news/${doc.uid || doc._meta.uid}`; // eslint-disable-line no-underscore-dangle
    case 'client':
      return `/family/${doc.uid || doc._meta.uid}`; // eslint-disable-line no-underscore-dangle
    default:
      return '/';
  }
}
