// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-digitaliseringsbyran-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/@digitaliseringsbyran/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-digitaliseringsbyran-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-client-js": () => import("./../src/templates/client.js" /* webpackChunkName: "component---src-templates-client-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-family-js": () => import("./../src/pages/family.js" /* webpackChunkName: "component---src-pages-family-js" */),
  "component---src-pages-friends-js": () => import("./../src/pages/friends.js" /* webpackChunkName: "component---src-pages-friends-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchensink-js": () => import("./../src/pages/kitchensink.js" /* webpackChunkName: "component---src-pages-kitchensink-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */)
}

